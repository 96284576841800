.container {
  max-width: 800px; /* Largeur maximale de la page */
  margin: 0 auto; /* Centrage de la page */
  padding: 5px; /* Espacement interne */
}

.search-bar {
  display: flex; /* Flexbox pour le formulaire */
  flex-direction: column; /* Colonne pour les champs */
  margin-bottom: 20px; /* Espacement en bas */
}

.small-form input {
  padding: 8px; /* Espacement interne */
  margin: 5px 0; /* Espacement vertical */
  font-size: 14px; /* Taille de police */
}

.small-form button {
  padding: 8px; /* Espacement interne */
  background-color: #007bff; /* Couleur de fond du bouton */
  color: white; /* Couleur du texte */
  border: none; /* Pas de bordure */
  cursor: pointer; /* Curseur pointer */
  font-size: 14px; /* Taille de police */
}

.small-form button:hover {
  background-color: #0056b3; /* Couleur de fond du bouton au survol */
}

h1 {
  font-size: 24px; /* Taille du titre */
}

.result-item {
  padding: 15px; /* Espacement interne */
  /*border: 1px solid #c5c5c5; /* Bordure grise  rgb(130, 130, 130) 0px 1px 10px -2px*/
  border-radius: 4px; /* Coins arrondis */
  margin-bottom: 10px; /* Espacement entre les éléments */
  /*background-color: #0f0f0f;*/
}

.result-title {
  font-weight: 500; /* Gras pour le titre */
  text-decoration: none; /* Pas de soulignement */
  color: #1a0dab; /* Couleur du titre */
  font-size: 1rem;
}

.result-location, .result-cost {
  font-size: 1rem; /* Taille de police */
  /*color: white; /* Couleur grise #555*/
}


.result-container {
  max-height: 100%; /* Ajustez la hauteur selon vos besoins */
  overflow-y: auto; /* Permet le défilement vertical */
  /*padding: 1rem; /* Un peu de rembourrage pour le style */
  /*background-color: #282828;
  /*background-color: ; /* Couleur de fond pour améliorer la visibilité */
  /*border-radius: 8px; /* Coins arrondis pour un meilleur design */
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px  90px  0px ;
}

.page-button {
  background-color: #e91e64; /* Couleur de fond */
  color: white; /* Couleur du texte */
  border: none; /* Supprime la bordure par défaut */
  border-radius: 5px; /* Coins arrondis */
  padding: 10px 15px; /* Espacement interne */
  margin: 0 5px; /* Espacement entre les boutons */
  cursor: pointer; /* Changer le curseur au survol */
  transition: background-color 0.3s, transform 0.3s; /* Transition pour les effets */
}

.page-button:hover {
  /*background-color: #555; /* Couleur de fond au survol */
  transform: scale(1.05); /* Léger agrandissement au survol */
}

.page-button.active {
  background-color: #007bff; /* Couleur pour le bouton actif */
}

.page-button.disabled {
  background-color: #444; /* Couleur pour le bouton désactivé */
  cursor: not-allowed; /* Curseur désactivé */
  opacity: 0.6; /* Légèrement transparent */
}


@media (max-width: 640px) { /* Taille d'écran pour mobile */
  .pagination {
    margin-bottom: 100px; /* Marge pour mobile */
  }
}