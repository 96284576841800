@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


body,*{
  /**
  font-style: normal;
  **/
  font-family: "PT Serif", serif;
  /*font-family: "Playwrite PE", cursive;*/
  font-optical-sizing: auto;
  font-style: normal;
}


.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background-size: cover;
}

#root {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 100%;*/
}

.content {
  color: white; /* Couleur du texte pour le rendre lisible */
  text-align: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Ajoute un fond semi-transparent pour améliorer la lisibilité */
  border-radius: 10px;
}

/* Styles supplémentaires pour s'assurer que la vidéo couvre tout l'espace sur les appareils mobiles */
@media (max-width: 768px) {
  .video-background {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
  }
}



body {
  background: white;/*#f3f4f6*/
  /*https://marketplace.canva.com/EAFk7zn4xJQ/1/0/1600w/canva-beige-wedding-photo-collage-KHZpcng-Uv8.jpg*/
  /**background: #db2777;**/
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/  
}
/*
body {
  margin: 0;
  background: linear-gradient(-45deg, #db2777, #f07ca8, #ffd1e9, #db2777);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
*/

/* Ajustez les styles de conteneur pour qu'ils n'affectent pas le corps de la page */
.container {
  background: transparent; /* Appliquer un fond blanc à l'intérieur du conteneur */
  border-radius: 15px;
  padding: 20px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
}


/*background: #f3f4f6;/*#fafafa*/
/*
background-image: url("/public/bg.webp");
background-repeat: no-repeat;
background-size: cover;
*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Ajoutez ces styles dans votre fichier CSS global ou là où vous importez les fichiers slick-carousel */
.slick-dots li button {
  width: 20px; /* Ajustez la largeur du dot */
  height: 20px; /* Ajustez la hauteur du dot */
  margin: 0 5px; /* Ajustez l'espacement entre les dots */
  border-radius: 50%; /* Pour rendre les coins arrondis */
  background-color: black !important; /* Couleur des dots (blanc) */
  border: none; /* Retirez la bordure si vous ne voulez pas */
  outline: none; /* Retirez la bordure si vous ne voulez pas */
  cursor: pointer;
  content: '';
}



/* Style de la galerie GRID */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 10px;
}

/* Style des éléments de la galerie */
.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style des images dans la galerie */
.gallery-item img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

/* Media query pour les écrans plus petits */
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}


/* Ajoutez ces styles dans votre fichier CSS global ou là où vous importez les fichiers slick-carousel */
.slick-prev, .slick-next {
  font-size: 24px;
  color: white;
  background-color: #2196F3; /* Couleur de fond des flèches */
  width: 40px; /* Largeur des flèches */
  height: 40px; /* Hauteur des flèches */
  border-radius: 50%; /* Pour rendre les coins arrondis */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.slick-prev {
  left: 10px; /* Ajustez la position de la flèche précédente */
}

.slick-next {
  right: 10px; /* Ajustez la position de la flèche suivante */
}

.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 103px !important;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev {
  /*left: 25px !important;*/
  left: 48px !important;
}

.slick-next{
  right: 0px !important
}


.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 50px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;}

  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px !important;
    line-height: 1;
    opacity: .75;
    color: black !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.slide-container {
  position: relative;
}

.click-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: #fddd01;/*navy*/
  border-color: #eebd29;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.45) !important;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}




.slide-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white; 
}

.slide-overlay h3 {
  margin: 0;
  font-size: 24px; 
}


/* Ajoutez ces styles pour les boutons de partage */
.share-button {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
}

/* Ajoutez ces styles pour les icônes à l'intérieur des boutons */
.share-icon {
  font-size: 18px;
  margin-left: 5px;
}



/* Ajoutez ces styles pour le titre du slider */
.slide-title {
  font-size: 16px; /* Taille de la police par défaut */
}

@media screen and (min-width: 768px) {
  .slide-title {
    font-size: 20px; /* Ajustez la taille de la police pour les écrans de largeur minimale de 768px */
  }
}

@media screen and (min-width: 1024px) {
  .slide-title {
    font-size: 24px; /* Ajustez la taille de la police pour les écrans de largeur minimale de 1024px */
  }
}

@media screen and (min-width: 1200px) {
  .slide-title {
    font-size: 28px; /* Ajustez la taille de la police pour les écrans de largeur minimale de 1200px */
  }
}





/* Home.css */
/* Ajoutez des styles personnalisés pour le composant Home ici */

/* Exemple de style pour le menu inférieur fixe */
/* Vous pouvez personnaliser ces styles selon vos besoins */
.bottom-navigation {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333; /* Changez cela selon votre conception */
  color: white;
  padding: 10px;
  text-align: center;
}



/* Single.css */

.wp-video {
  max-width: 100%; /* La vidéo ne dépassera pas la largeur de son conteneur */
  width: 338px; /* Largeur maximale absolue si l'espace est disponible */
  height: auto; /* La hauteur s'ajustera automatiquement en fonction de la largeur */
}


/* Styles pour rendre les balises iframe responsives */
.container iframe {
  max-width: 100%;
  height: auto;
}


/* Styles pour rendre les vidéos responsives */
.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* Ajustez ce pourcentage en fonction du rapport hauteur/largeur de vos vidéos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.hidden-video .video-background {
  display: none;
}




.wishlist-button {
  cursor: pointer;
  transition: color 0.3s;
}

.wishlist-button.active {
  color: #E91E63;
}


.custom-cancel-button{
background: #333;
}



/* styles.css ou votre fichier CSS */
.input-zoom-container {
  position: relative;
  overflow: hidden;
}

.input-zoom {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.input-zoom:focus,
.input-zoom:hover {
  transform: scale(1.05); /* Zoom avant */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optionnel : ajoutez une ombre pour l'effet */
}



@keyframes heartbeat {
  0% {
    transform: scale(1);
    color: #E91E63;
  }
  50% {
    transform: scale(1.2);
    color: white;
  }
  100% {
    transform: scale(1);
    color: #E91E63;
  }
}

.animate-heartbeat {
  animation: heartbeat 1s infinite;
}



@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
}

.pulse-button {
  animation: pulse 1.5s infinite;
}


.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.skeleton-item {
  width: 200px;
}

/* Ajouter ce code à votre fichier CSS */
.smooth-pulse {
  @apply animate-pulse;
  animation-duration: 2s; /* Changez la durée pour ralentir l'animation */
}



@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.animate-zoom {
  animation: zoomInOut 0.3s ease-in-out;
}


.swal2-cancel-button {
  background-color: #d3d3d3 !important; /* Gris clair */
  color: #000 !important; /* Texte en noir */
}


.dark-overlay {
  position: fixed; /* La superposition couvre toute la fenêtre */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur noire avec transparence à 50% */
  /*z-index: 10; /* Pour être au-dessus des autres éléments */
  pointer-events: none; /* Empêche les clics, si nécessaire */
}
